const site = {
  pathPrefix: '/10y-in-jp',
  title: '在日十年',
  author: 'Daniel Liu',
  description: '时光飞逝，在日十年。我用平凡的文字，记录方方面面的感慨。',
  siteUrl: 'https://10y-in-jp.thundermiracle.com/',
  twitter: 'thundermiracle',
  medium: 'thundermiracle',
  github: 'thundermiracle',
  facebook: 'thundermiracle',
  disqusShortName: '10y-in-jp-thundermiracle-com',
  googleTrackingId: 'UA-119418003-2',
  lang: 'zh-cmn',
};

const text = {
  tHome: '首页',
  tRelativePosts: '相关文章',
  tFollowTwitterDescription: 'You should follow him on Twitter',
  tTags: '所有标签',
  tIndTitle: '所有文章',
  taIndKeywords: [`blog`, `gatsby`, `日本`, `十年`, '在日十年', '博客'],
  tfIndCountPosts: count => `共 ${count} 篇文章`,
  tfTagHeader: (totalCount, tag) => `标签"${tag}" 下共有 ${totalCount} 篇文章`,
  t404Title: 'Not Found',
  t404Content: 'You just hit a route that doesn&#39;t exist... the sadness.',
};

module.exports = {
  site,
  text,
};
